import { useState, useMemo } from "react";
import { BsArrowDownUp } from "react-icons/bs";

const ChargesTable = ({ currentPosts, handleCheckboxChange, selectedIds, selectAll, handleSelectAll }) => {

    const [sortColumn, setSortColumn] = useState("");
    const [sortDirection, setSortDirection] = useState("");

    const sortedData = useMemo(() => {
        const sortedArray = [...currentPosts];

        if (sortColumn !== "") {
            sortedArray.sort((a, b) => {
                const valueA = a[sortColumn];
                const valueB = b[sortColumn];

                if (sortColumn === "lastmodified") {
                    // Convert timestamps to Date objects for comparison
                    const dateA = new Date(valueA).getTime();
                    const dateB = new Date(valueB).getTime();

                    if (dateA < dateB) return sortDirection === "asc" ? -1 : 1;
                    if (dateA > dateB) return sortDirection === "asc" ? 1 : -1;
                    return 0;
                } else {
                    if (valueA < valueB) return sortDirection === "asc" ? -1 : 1;
                    if (valueA > valueB) return sortDirection === "asc" ? 1 : -1;
                    return 0;
                }
            });
        }

        return sortedArray;
    }, [currentPosts, sortColumn, sortDirection]);

    const handleSort = (column) => {
        if (column === sortColumn) {
            setSortDirection((prevSortDirection) =>
                prevSortDirection === "asc" ? "desc" : "asc"
            );
        } else {
            setSortColumn(column);
            setSortDirection("asc");
        }
    };
    // eslint-disable-next-line
    const numberFormat = (value) =>
        new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR'
        }).format(value);
    return (
        <>
            <table id="" className="table table-bordered">
                <thead>
                    <tr>
                        <th className="text-center" scope="row" style={{ width: "3%" }} >
                            <input
                                type="checkbox"
                                checked={
                                    currentPosts.length > 0 &&
                                    currentPosts.every(item => selectedIds.includes(item.id))
                                }
                                onChange={handleSelectAll}
                            />
                        </th>
                        <th className="" style={{ width: "3%" }} scope="col" onClick={() => handleSort("id")}>
                            <div className="d-flex flex-row items-center space-x-1">
                                <BsArrowDownUp /><span className="ps-1">ID</span>
                            </div>
                        </th>
                        <th className="" style={{ width: "10%" }} scope="col" onClick={() => handleSort("taskid")}>
                            <div className="d-flex flex-row items-center space-x-1">
                                <BsArrowDownUp /><span className="ps-1">Taskid</span>
                            </div>
                        </th>
                        <th className="" style={{ width: "4%" }} scope="col" onClick={() => handleSort("segNo")}>
                            <div className="d-flex flex-row items-center space-x-1">
                                <BsArrowDownUp /><span className="ps-1">SeqNo</span>
                            </div>

                        </th>
                        <th className="" style={{ width: "5%" }} scope="col" onClick={() => handleSort("activity")}>
                            <div className="d-flex flex-row items-center space-x-1">
                                <BsArrowDownUp /> <span className="ps-1">Activity</span>
                            </div>
                        </th>
                        <th className="" style={{ width: "5%" }} scope="col" onClick={() => handleSort("projectid")}>
                            <div className="d-flex flex-row items-center space-x-1">
                                <BsArrowDownUp /> <span className="ps-1">Projectid</span>
                            </div>
                        </th>
                        <th className="" style={{ width: "7%" }} scope="col" onClick={() => handleSort("userid")}>
                            <div className="d-flex flex-row items-center space-x-1">
                                <BsArrowDownUp /> <span className="ps-1">UserID</span>
                            </div>
                        </th>
                        <th className="" style={{ width: "5%" }} scope="col" onClick={() => handleSort("wordcounts")}>
                            <div className="d-flex flex-row items-center space-x-1">
                                <BsArrowDownUp /> <span className="ps-1">Wordcounts</span>
                            </div>
                        </th>
                        <th className="" style={{ width: "3%" }} scope="col" onClick={() => handleSort("rate")}>
                            <div className="d-flex flex-row items-center space-x-1">
                                <BsArrowDownUp /> <span className="ps-1">Rate</span>
                            </div>

                        </th>
                        <th className="" style={{ width: "5%" }} scope="col" onClick={() => handleSort("amount")}>
                            <div className="d-flex flex-row items-center space-x-1">
                                <BsArrowDownUp /> <span className="ps-1">Amount</span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {sortedData.map((myData, index) => (
                        <tr key={myData.id} style={{ backgroundColor: selectedIds.includes(myData.id) ? '#c1d3f0' : '' }}>
                            <td className="">
                                <div className="custom-control custom-checkbox text-center"
                                    style={{ 'whiteSpace': 'pre-wrap', wordWrap: "break-word", overflow: "hidden" }}
                                >
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={`customCheck${myData.id}`}
                                        checked={selectedIds.includes(myData.id) || selectAll}
                                        onChange={() => handleCheckboxChange(myData.id)}
                                    />
                                </div>
                            </td>
                            <th className="text-center" scope="row">{myData.id}</th>
                            <td className="wrap-text-style wrap-text-style">{myData.taskid}</td>
                            <td className="wrap-text-style wrap-text-style">{myData.seq_no}</td>
                            <td className="wrap-text-style wrap-text-style">{myData.activity}</td>
                            <td className="wrap-text-style wrap-text-style">{myData.projectid}</td>
                            <td className="wrap-text-style wrap-text-style">{myData.userid}</td>
                            <td className="wrap-text-style wrap-text-style">{myData.wordcounts}</td>
                            <td className="wrap-text-style wrap-text-style">{myData.rate}</td>
                            <td className="wrap-text-style wrap-text-style">{myData.amount}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default ChargesTable;
