import React, { useState, useEffect } from 'react';
import { GlobalContext } from './GlobalContext';
import axios from "axios";
import ToastNotification from './ToastNotification';

const GlobalProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [activityFilterData, setActivityFilterData] = useState([]);
  const [chargesData, setChargesData] = useState([]);
  const [filterChargesData, setFilterChargesData] = useState([]);
  const [ratesData, setRatesData] = useState([]);
  const [ratesFilterData, setRatesFilterData] = useState([]);
  const [users, setUsers] = useState([])
  const [projects, setProjects] = useState([])


  const API_URL = process.env.REACT_APP_API_URL;

  const [toast, setToast] = useState(null);
  const showToast = (type, message, position, distance) => {
    setToast({ type, message, position, distance });
    setTimeout(() => {
      setToast(null);
    }, 7000); // Adjust the duration as needed
  };

  useEffect(() => {
    const FetchData = async () => {
      try {
        const response = await axios.post(`${API_URL}/tasksData`);
        setData(response.data);
        setFilterData(response.data);
      } catch (error) {
        console.log("error in taskData:", error);
      }
    };
    FetchData();
    console.log(data);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const FetchData = async () => {
      try {
        const response = await axios.post(`${API_URL}/activityData`);
        setActivityData(response.data);
        setActivityFilterData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    FetchData();
    console.log(data);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const FetchData = async () => {
      try {
        const response = await axios.post(`${API_URL}/chargesData`);
        setChargesData(response.data);
        setFilterChargesData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    FetchData();
    console.log(data);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const FetchData = async () => {
      try {
        const response = await axios.post(`${API_URL}/ratesData`);
        setRatesData(response.data);
        setRatesFilterData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    FetchData();
    console.log(data);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const FetchData = async () => {
      try {
        const response = await axios.post(`${API_URL}/users`);
        setUsers(response.data);
      } catch (error) {
        console.log("error in taskData:", error);
      }
    };
    FetchData();
    console.log(data);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const ProjectsData = async () => {
      try {
        const response = await axios.post(`${API_URL}/projects`);
        setProjects(response.data);
      } catch (error) {
        console.log("error Projects:", error);
      }
    };
    ProjectsData();
    console.log(data);
    // eslint-disable-next-line
  }, []);

  const contextValue = { API_URL, data, filterData, setFilterData, activityData, activityFilterData, setActivityData, setActivityFilterData, chargesData, setChargesData, setFilterChargesData, filterChargesData, ratesData, setRatesData, ratesFilterData, setRatesFilterData, users, setUsers, projects, setProjects, showToast };

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
      {toast && <ToastNotification type={toast.type} message={toast.message} position={toast.position}
        distance={toast.distance} />}
    </GlobalContext.Provider>
  );
}

export default GlobalProvider;
