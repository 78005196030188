const Editable = ({ columns, data }) => {

    return (
        <table className="table table-bordered" style={{ color: "#222" }}>
            <thead className="text-center">
                <tr>
                    {columns.map((column) => (
                        <th key={column.key} className={column.className}>
                            {column.title}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {columns.map((column, colIndex) => (
                            <td key={colIndex}>{row[column]}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>

    );
};

export default Editable;
