import { useState, useContext, useEffect } from 'react'
import Table from './Table'
import Search from '../../components/Search'
import { GlobalContext } from '../../context/GlobalContext';
import Pagination from "../../components/Pagination";

const Tasks = () => {
    const { data, filterData, setFilterData } = useContext(GlobalContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(10);
    const [selectedIds, setSelectedIds] = useState([]);
    // eslint-disable-next-line 
    const [selectAll, setSelectAll] = useState(false);
    const [fileCount, setFileCount] = useState(0);
    const [totalWords, setTotalWords] = useState(0);

    // Make sure filterData is defined and not null
    const filteredData = filterData || "";

    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;

    // Slice the filteredData array
    const currentPosts = filteredData.slice(indexOfFirstPost, indexOfLastPost);
    const howManyPages = Math.ceil(filteredData.length / postsPerPage);

    const handleCheckboxChange = (id) => {
        if (selectedIds.includes(id)) {
            setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
        } else {
            setSelectedIds([...selectedIds, id]);
        }
    };

    const handleSelectAll = () => {
        const currentPageIds = currentPosts.map(item => item.id);
        const allSelected = currentPageIds.every(id => selectedIds.includes(id));

        if (allSelected) {
            setSelectedIds(selectedIds.filter(id => !currentPageIds.includes(id)));
        } else {
            setSelectedIds([...selectedIds, ...currentPageIds]);
        }
    };

// eslint-disable-next-line 
    const checkAll = () => {
        const confirmCheckAll = window.confirm("Are you sure you want to check all data?");

        if (confirmCheckAll) {
            const currentPageIds = filterData.map(item => item.id);
            const allSelected = currentPageIds.every(id => selectedIds.includes(id));

            if (allSelected) {
                setSelectedIds(selectedIds.filter(id => !currentPageIds.includes(id)));
            } else {
                setSelectedIds([...selectedIds, ...currentPageIds]);
            }
        }
    };

    // eslint-disable-next-line 
    const handleRemoveText = (indexToRemove) => {
        setSelectedIds((prevSelectedIds) => {
            const updatedSelectedIds = prevSelectedIds.filter((_, index) => index !== indexToRemove);
            return updatedSelectedIds;
        });
    };

    useEffect(() => {
        let wordsCount = 0;
        const uniqueTaskIds = Array.from(new Set(selectedIds));
    
        uniqueTaskIds.forEach((taskId) => {
            const correspondingDataItem = filterData.find((dataItem) => dataItem.id === taskId);
            if (correspondingDataItem) {
                wordsCount += parseInt(correspondingDataItem.wordcounts);
            }
        });
    
        setTotalWords(wordsCount);
        setFileCount(uniqueTaskIds.length);
        // eslint-disable-next-line 
    }, [selectedIds]);
    

    return (
        <>
            <div className="container mb-5">
                <div className='mb-1 mt-2'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <Search data={data} setFilterData={setFilterData} />
                        </div>
                        <div className='col-md-6 mt-2'>
                            <div> <span className='border bg-primary text-white mx-3 p-2 mr-5 link_text'>File: {fileCount} </span> <span className='border bg-primary text-white p-2 link_text'>Words Count: {totalWords} </span> </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Table
                        currentPosts={currentPosts}
                        handleCheckboxChange={handleCheckboxChange}
                        selectedIds={selectedIds}
                        selectAll={selectAll}
                        handleSelectAll={handleSelectAll}
                    />
                </div>
            </div>

            <div className='text-md-right'>
                <div className='d-flex justify-content-between'>
                    <div className='ml-5'>
                        <label htmlFor="">Jump to</label>
                        <select
                            className='mx-2'
                            style={{ width: "50px", height: "30px" }}
                            value={postsPerPage}
                            onChange={(e) => {
                                setPostsPerPage(parseInt(e.target.value));
                                setCurrentPage(1);
                            }}
                        >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                        <span>Page {currentPage} of {howManyPages}</span>
                    </div>
                    <div className='mx-5 mb-5'>
                        <Pagination pages={howManyPages} setCurrentPage={setCurrentPage} />
                    </div>
                </div>
            </div>

        </>
    );
};

export default Tasks;
