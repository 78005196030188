import { useState } from "react";
import Editable from "../../Editable/Editable";

const Payments = () => {
    // eslint-disable-next-line 
    const [data, setData] = useState([])
    const columns = [
        { key: 'id', title: 'ID', className: 'w-5 px-2 border' },
        { key: 'name', title: 'Name', className: 'w-10 px-2 border' },
        { key: 'amount', title: 'Amount', className: 'w-10 px-2 border' },
        { key: 'balance', title: 'Balance', className: 'w-10 px-2 border' },
    ];

    //   const data = [
    //     { id: 1, name: 'John', age: 25, regNum:122345 },
    //     { id: 2, name: 'Jane', age: 30, regNum:122345 },
    //     { id: 2, name: 'Jane', age: 30, regNum:122345 },
    //     { id: 2, name: 'Jane', age: 30, regNum:122345 },
    //     { id: 2, name: 'Jane', age: 30, regNum:122345 },

    //   ];

    return (
        <>
            {data.length > 0 && data ? (
                <div className=" px-2">
                    <Editable columns={columns} data={data} />
                </div>
            ) : (
                <table className="table table-bordered mt-2 border-gray-300">
                    <tr>
                        <td className="border border-gray-300 py-3 text-xl text-center" colSpan="3">No Data available </td>
                    </tr>
                </table>
            )}


        </>
    );
};

export default Payments;